import prefillForm from './utils/prefillForm';
import checkFormSuccess from './utils/checkFormSuccess';
import getCookie from './utils/getCookie';

/**
 ** Autofill the form & redirect the user to the pay link with autofilled link
 */
const handleZoningForm = function () {
  const form = document.querySelector('#zoning-form');
  if (!form) return;

  // Get userProfile form cookie
  const userProfile = getCookie('userProfile');
  if (userProfile) prefillForm(form, userProfile);

  form.addEventListener('submit', async (e) => {
    e.preventDefault();

    const email = form.querySelector('#zoning-email').value;

    // Form successfuly submitted
    await checkFormSuccess(form);

    //  Redirect to payment page after half a second
    setTimeout(() => {
      window.open(
        `https://pay.zomes.com/b/eVa5m57LX4MF3jq8wA?prefilled_email=${email}&prefilled_promo_code=gift`,
        '_blank'
      );
    }, 3000);
  });
};

export { handleZoningForm };

import saveUserProfile from './saveUserProfile';
import prefillForm from './utils/prefillForm';
import checkFormSuccess from './utils/checkFormSuccess';
import getCookie from './utils/getCookie';

/**
 ** Send form data customer.io and identify the person
 */
const handleNewsletterForm = function () {
  const form = document.querySelector('#Webflow-Newsletter-Form');
  if (!form) return;

  form.addEventListener('submit', (e) => {
    e.preventDefault();

    // const email = form.querySelector('#newsletter-email').value;
    const formData = new FormData(form);
    // const formDataObject = Object.fromEntries(formData.entries());

    //* Identifying the person
    // analytics.identify(email, {
    //   anonymousID: analytics.user().anonymousId(),
    //   ...formDataObject,
    // });

    //* Track the event
    // analytics.track('form_submit', {
    //   form_name: 'Newsletter',
    // });

    //* Store data to local storage
    saveUserProfile(form);
  });
};

/**
 ** Send form data customer.io and identify the person
 */
const handleContactForm = function () {
  const form = document.querySelector('#Webflow-Contact-Form');
  if (!form) return;

  form.addEventListener('submit', (e) => {
    e.preventDefault();

    // const email = form.querySelector('#contact-email').value;
    const formData = new FormData(form);
    // const formDataObject = Object.fromEntries(formData.entries());

    //* Identifying the person
    // analytics.identify(email, {
    //   anonymousID: analytics.user().anonymousId(),
    //   ...formDataObject,
    // });

    //* Track the event
    // analytics.track('form_submit', {
    //   form_name: 'Contact',
    // });

    //* Store data to local storage
    saveUserProfile(form);
  });
};

/**
 ** Autofill the form & redirect the user to the pay link with autofilled link
 */
const handleZoningForm = function () {
  const form = document.querySelector('#zoning-form');
  if (!form) return;

  // Get userProfile form local storage and prefill the form
  const userProfile = JSON.parse(localStorage.getItem('userProfile'));
  prefillForm(form, userProfile);

  form.addEventListener('submit', async (e) => {
    e.preventDefault();

    const email = form.querySelector('#zoning-email').value;
    // const formData = new FormData(form);
    // const formDataObject = Object.fromEntries(formData.entries());

    //* Identifying the person
    // analytics.identify(email, {
    //   anonymousID: analytics.user().anonymousId(),
    //   ...formDataObject,
    // });

    //* Track the event
    // analytics.track('form_submit', {
    //   form_name: 'Zoning',
    // });

    // Store data to local storage
    saveUserProfile(form);

    // Form successfuly submitted
    await checkFormSuccess(form);

    //  Redirect to payment page after half a second
    setTimeout(() => {
      window.open(
        `https://pay.zomes.com/b/eVa5m57LX4MF3jq8wA?prefilled_email=${email}&prefilled_promo_code=gift`,
        '_blank'
      );
    }, 3000);
  });
};

/**
 ** Handle calendly form book page
 */
const handleCalendlyForm = function () {
  // Check user profile in cookie > localstorage > None
  const userProfileInCookie = getCookie('userProfile');
  const userProfileInLocalStorage = JSON.parse(
    localStorage.getItem('userProfile')
  );
  const userProfile = userProfileInCookie || userProfileInLocalStorage || null;
  const userConfig = getCookie('userConfig');

  // Update the Calendly URL with the pre-filled values
  const calendlyWidget = document.querySelector('.calendly-inline-widget');
  let calendlyDataURL = null;

  if (!calendlyWidget) return;

  if (userProfile)
    calendlyDataURL = `https://calendly.com/karim-zomes/zomes-intro-call?name=${encodeURIComponent(
      userProfile.name
    )}&email=${encodeURIComponent(
      userProfile.email
    )}&location=${encodeURIComponent(
      userProfile.phone ?? ''
    )}&a2=${encodeURIComponent(
      'design.zomes.com?config=' + (userConfig?.config ?? '')
    )}`;

  if (!userProfile)
    calendlyDataURL = `https://calendly.com/karim-zomes/zomes-intro-call?`;

  calendlyWidget.setAttribute('data-url', calendlyDataURL);

  // Load the Calendly widget script
  const script = document.createElement('script');
  script.src = 'https://assets.calendly.com/assets/external/widget.js';
  script.async = true;
  document.body.appendChild(script);
};

export {
  handleNewsletterForm,
  handleContactForm,
  handleZoningForm,
  handleCalendlyForm,
};

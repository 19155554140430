const popupOpen = function () {
  let isPopupOpen = false;

  const contactBtn = document.querySelector('#contact-button');
  const closePopupBtn = document.querySelector('#popup-close-button');
  const popupOverlay = document.querySelector('#popup-overlay');

  if (!popupOverlay && !closePopupBtn && !contactBtn) return;

  [contactBtn, closePopupBtn, popupOverlay].forEach((element) => {
    element.addEventListener('click', togglePopup);
  });

  /**
   *
   */
  function disableScrolling() {
    document.body.style.overflow = 'hidden';
  }
  function enableScrolling() {
    document.body.style.overflow = '';
  }
  function togglePopup() {
    isPopupOpen = !isPopupOpen;
    isPopupOpen ? disableScrolling() : enableScrolling();
  }
};

export default popupOpen;

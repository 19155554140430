function getCookie(name) {
  const cookieString = document.cookie
    .split('; ')
    .find((row) => row.startsWith(name + '='));

  if (!cookieString) return null;

  const value = cookieString.split('=')[1];

  try {
    return JSON.parse(decodeURIComponent(value));
  } catch {
    return decodeURIComponent(value);
  }
}

export default getCookie;

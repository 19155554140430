import getCookie from './utils/getCookie';

const handleBooking = function () {
  let bookingLink = null;
  const parentEl = document.querySelector('#booking-embed');

  if (!parentEl) return;

  // Check user profile & config in cookie
  const userProfile = getCookie('userProfile');
  const userConfig = getCookie('userConfig');

  if (userProfile) {
    bookingLink = `https://meetings.hubspot.com/karim78?embed=true&firstname=${encodeURIComponent(
      userProfile.firstname
    )}&lastname=${encodeURIComponent(
      userProfile.lastname
    )}&email=${encodeURIComponent(
      userProfile.email
    )}&phone=${encodeURIComponent(
      userProfile.phone ?? ''
    )}&configurator=${encodeURIComponent(
      'design.zomes.com?config=' + (userConfig?.config ?? '')
    )}`;
  }

  if (!userProfile) {
    bookingLink = `https://meetings.hubspot.com/karim78?embed=true`;
  }

  // Create the iframe div
  const bookingDiv = document.createElement('div');
  bookingDiv.className = 'meetings-iframe-container';
  bookingDiv.setAttribute('data-src', `${bookingLink}`);

  // Create the iframe script
  const bookingScript = document.createElement('script');
  bookingScript.type = 'text/javascript';
  bookingScript.src =
    'https://static.hsappstatic.net/MeetingsEmbed/ex/MeetingsEmbedCode.js';

  // Append to the parentEl
  parentEl.appendChild(bookingDiv);
  parentEl.appendChild(bookingScript);
};

export default handleBooking;

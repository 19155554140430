const bannerState = function () {
  const bannerCloseBtn = document.querySelector('#banner-close-btn');
  const bannerSection = document.querySelector('#banner-section');

  // If banner exist on the page
  if (!bannerCloseBtn || !bannerSection) return;

  if (localStorage.getItem('bannerUserClosed') === 'true') {
    bannerSection.style.display = 'none';
    return;
  }

  bannerCloseBtn.addEventListener('click', () => {
    localStorage.setItem('bannerUserClosed', 'true');
  });
};

export default bannerState;

import popupOpen from './js/popupOpen';
import bannerState from './js/bannerState';
import handleBooking from './js/handleBooking';
import { handleZoningForm } from './js/handleForm';

window.Webflow ||= [];
window.Webflow.push(() => {
  popupOpen();
  bannerState();
  handleBooking();
  handleZoningForm();
});
